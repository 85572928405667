KisoThemes.hookOnPageLoad(function () {
  var hookupRemove = function () {
    $('.kiso-stripe-payments-payments-plans-remove-feature')
      .off('click')
      .on('click', function () {
        $(this).closest('.form-group').remove()
      })
  }

  $('.kiso-stripe-payments-payments-stripe-plan-add-feature')
    .off('click')
    .on('click', function () {
      var counter =
        $('.kiso-stripe-payments-payments-plan-feature-fields').children()
          .length + 1

      var template = `
      <div class="form-group">
        <div class="d-flex align-items-center">
          <div class="kiso-stripe-payments-payments-feature-name d-flex flex-column mr-3">
            <strong>Feature Name</strong>
            <input id="plan__featurename_${counter}" type="text" class="form-control" name="stripe_plan[features][][name]" />
          </div>
          <div class="kiso-stripe-payments-payments-feature-value d-flex flex-column">
            <strong>Feature Value</strong>
            <input id="plan__featurevalue_${counter}" type="text" class="form-control" name="stripe_plan[features][][value]" />
          </div>
          <a href="javascript:;" class="kiso-stripe-payments-payments-plans-remove-feature ml-3 mt-4">Remove</a>
        </div>
      </div>
    `

      $(template).appendTo('.kiso-stripe-payments-payments-plan-feature-fields')
      hookupRemove()
    })

  hookupRemove()
})
