import KisoStripePaymentsConfig from './kiso_stripe_payments_config'
import StripeCheckoutButton from './stripe_checkout_buttons'

var KisoStripePaymentsErrorHandler = {
  showError: function (error) {
    switch (KisoStripePaymentsConfig.showPaymentErrorAs) {
      case 'div':
        this.showAsDiv(error)
        break
      case 'redirect':
        this.redirect(error)
        break
      default:
        this.showAsConsoleLog(error)
    }
  },

  showAsDiv: function (errorObj) {
    var errorClass = KisoStripePaymentsConfig.errorDivClass
    $(errorClass)
      .html(
        errorObj.error + ' <br/><small>TX ID: ' + errorObj.tx_id + '</small>'
      )
      .show()
    StripeCheckoutButton.enableButton()
    console.log(errorObj)
  },

  redirect: function (errorObj) {
    if (KisoStripePaymentsConfig.redirectAfterTransactionFailurePath) {
      window.location =
        KisoStripePaymentsConfig.redirectAfterTransactionFailurePath +
        '/' +
        errorObj.txId
    } else {
      alert(
        "You chose to use a redirect after failure, but didn't define `redirectAfterTransactionFailurePath` in KisoStripePaymentsConfig."
      )
    }
  },

  showAsConsoleLog: function (errorObj) {
    console.error(errorObj)
  },
}

export default KisoStripePaymentsErrorHandler
