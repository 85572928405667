import KisoStripePaymentsPoller from './kiso_stripe_payments_poller'
import KisoStripePaymentsErrorHandler from './kiso_stripe_payments_error_handler'

var StripeCheckoutButton = {
  initialize: function () {
    $(document).off('click.stripe-checkout-button')
    $(document).on(
      'click.stripe-checkout-button',
      '.stripe-checkout-button',
      function (e) {
        e.preventDefault()
        StripeCheckoutButton.onButtonClick($(this))
      }
    )
  },

  onButtonClick: function (button) {
    var form = button.parent('form')
    var formDataAttrs = form.data()
    var formId = formDataAttrs.formId
    var chargeVerification = formDataAttrs.chargeVerification
    var productSku = formDataAttrs.productSku
    var productName = formDataAttrs.productName
    var configParams = {}

    $.each(Object.keys(formDataAttrs), function (_, key) {
      if (key.match(/^stripe/)) {
        let configKey = key.replace(/^stripe/, '')
        configKey = configKey.charAt(0).toLowerCase() + configKey.slice(1)
        configParams[configKey] = formDataAttrs[key]
      }
    })

    configParams.token = function (token) {
      StripeCheckoutButton.onTokenIssued(
        formId,
        chargeVerification,
        productSku,
        productName,
        token,
        configParams
      )
    }

    var handler = StripeCheckout.configure(configParams)

    handler.open()
  },

  onTokenIssued: function (
    formId,
    chargeVerification,
    productSku,
    productName,
    token,
    stripe_params
  ) {
    var form = $('#' + formId)
    var submitUrl = form.attr('action')

    form.append($('<input type="hidden" name="stripe_token">').val(token.id))
    form.append($('<input type="hidden" name="email">').val(token.email))
    form.append(
      $('<input type="hidden" name="price_cents">').val(stripe_params.amount)
    )
    form.append(
      $('<input type="hidden" name="currency">').val(stripe_params.currency)
    )
    form.append($('<input type="hidden" name="product_sku">').val(productSku))
    form.append($('<input type="hidden" name="product_name">').val(productName))
    form.append(
      $('<input type="hidden" name="charge_verification">').val(
        chargeVerification
      )
    )

    StripeCheckoutButton.submitForm(submitUrl, form.serialize(), token.id)
  },

  disableButton: function () {
    $('.stripe-checkout-button').prop('disabled', true)
    $('.stripe-checkout-button-text').hide()
    $('.stripe-checkout-button-spinner').show()
  },

  enableButton: function () {
    $('.stripe-checkout-button').prop('disabled', false)
    $('.stripe-checkout-button-text').show()
    $('.stripe-checkout-button-spinner').hide()
  },

  successButton: function () {
    $('.stripe-checkout-button')
      .removeClass('btn-primary')
      .addClass('btn-success')
    $('.stripe-checkout-button-text').text('Payment Successful').show()
    $('.stripe-checkout-button-spinner').hide()
  },

  submitForm: function (url, data, stripe_token) {
    StripeCheckoutButton.disableButton()

    $.ajax({
      type: 'POST',
      url: url,
      data: data,
      success: function (data) {
        KisoStripePaymentsPoller.pollTransactionStatus(stripe_token, 60)
      },
      error: function (data) {
        KisoStripePaymentsErrorHandler.showError(
          jQuery.parseJSON(data.responseText).error
        )
      },
    })
  },
}

KisoThemes.hookOnPageLoad(function () {
  StripeCheckoutButton.initialize()
})

export default StripeCheckoutButton
