$(document).on('turbolinks:load', function() {
  lockIcon = '<span class="tooltip-custom">\
                <i class="fa fa-lock" style="margin-left:5px"></i>\
                <span class="tooltip-text">\
                  The amount and interval of this plan cannot be changed as it has been used in a transaction. Create a new price to make other changes.\
                </span>\
              </span>'

  if($('body.controller-plans.action-edit').length) {
    $('h1').append(lockIcon)
    $("#stripe_plan_price_cents").prop('disabled', true);
    $("#stripe_plan_price_cents").parent().addClass('custom-disabled-input')
    $("#stripe_plan_interval_count").prop('disabled', true);
    $("#stripe_plan_interval_count").parent().addClass('custom-disabled-input')
    $("input[name='stripe_plan[interval]']").prop('disabled', true);
    $("label[for='stripe_plan_Plan Interval']").parent().addClass('custom-disabled-input')
  }
})
