import KisoStripePaymentsConfig from './kiso_stripe_payments_config'
import StripeCheckoutButton from './stripe_checkout_buttons'

var KisoStripePaymentsSuccessHandler = {
  showSuccess: function (successObj) {
    StripeCheckoutButton.successButton()

    switch (KisoStripePaymentsConfig.showPaymentSuccessAs) {
      case 'div':
        this.showAsDiv(successObj)
        break
      case 'redirect':
        this.redirect(successObj)
        break
      default:
        break
    }
  },

  showAsDiv: function () {
    var successClass = KisoStripePaymentsConfig.successDivClass
    $(successClass)
      .html(KisoStripePaymentsConfig.transactionSuccessMessage)
      .show()
  },

  redirect: function (successObj) {
    if (KisoStripePaymentsConfig.redirectAfterTransactionSuccessPath) {
      window.location =
        KisoStripePaymentsConfig.redirectAfterTransactionSuccessPath +
        '/' +
        successObj.tx_id
    } else {
      alert(
        "You chose to use a redirect after success, but didn't define `redirectAfterTransactionSuccessPath` in KisoStripePaymentsConfig."
      )
    }
  },
}

export default KisoStripePaymentsSuccessHandler
