import KisoStripePaymentsConfig from './kiso_stripe_payments_config'
import KisoStripePaymentsSuccessHandler from './kiso_stripe_payments_success_handler'
import KisoStripePaymentsErrorHandler from './kiso_stripe_payments_error_handler'

var KisoStripePaymentsPoller = {
  pollTransactionStatus: function (tokenId, retryAttempts) {
    if (retryAttempts === 0) {
      KisoStripePaymentsErrorHandler.showError(
        "We can't seem to process your payment right now. Please email support with the following payment attmept ID: '" +
          tokenId
      )
      return
    }

    var onStatusPollSuccess = function (data) {
      switch (data.status) {
        case 'complete':
          KisoStripePaymentsSuccessHandler.showSuccess(data)
          break
        case 'failed':
          KisoStripePaymentsErrorHandler.showError(data.error)
          break
        default:
          setTimeout(function () {
            KisoStripePaymentsPoller.pollTransactionStatus(
              tokenId,
              retryAttempts - 1
            )
          }, 500)
          break
      }
    }

    var onStatusPollFailure = function (xhr) {
      var message = jQuery.parseJSON(xhr.responseText)
      KisoStripePaymentsErrorHandler.showError(message)
    }

    $.ajax({
      type: 'GET',
      url: KisoStripePaymentsConfig.transactionStatusPollUrl + '/' + tokenId,
      success: onStatusPollSuccess,
      error: onStatusPollFailure,
    })
  },
}

export default KisoStripePaymentsPoller
