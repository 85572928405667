var KisoStripePaymentsConfig = {
  // Show payment errors as a either div or redirect
  showPaymentErrorAs: 'div',

  // The CSS class of a div on your page to show a payment failure
  errorDivClass: '.stripe-transaction-error',

  // The path to redirect to on payment failure
  redirectAfterFailurePath: '/sorry',

  // Show payment successes as a redirect to another page (will receive the
  // Stripe TX id as a query parameter `tx`)
  showPaymenSuccessAs: 'redirect',

  // The CSS class of a div on your page to show a payment success
  successDivClass: '.stripe-transaction-success',

  // The path to redirect to on a payment success
  redirectAfterSuccessPath: '/success',

  // The path to poll the transaction status (inbuilt to KISO Stripe Payments)
  transactionStatusPollUrl: '/kiso_stripe_payments/transaction_status',
}

export default KisoStripePaymentsConfig
